import React, {useContext, useState} from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {POLICIES_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


function CreatePolicy () {
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({name: "", documents: []});

  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };
  const clearForm = () => {
    setData({
      name: "",
      documents: []
    })
    const fileInput = document.querySelector('input[type="file"]');
    fileInput.value = '';
  }

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setData({...data, documents: selectedFiles});
  }
  const handleRemoveFile = (index) => {
    // Remove the file at the specified index
    const updatedFiles = [...data.documents];
    updatedFiles.splice(index, 1);
    setData({...data, documents: updatedFiles});
  };

  const submitPolicy = async () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    data.documents.forEach((item, index) => {
      formData.append("files", item);
    });
    try {
      const response = await fetch(POLICIES_URL +  "create/", {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        setIsUploading(false);
        clearForm();
      } else {
        console.error('Failed to create policy');
        const responseData = await response.json();
        setError(extractErrorMessage(responseData));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Failed to create policy', error);
      setError("Unexpected Error. Please contact admin.");
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.documents) {
      await submitPolicy();
    } else {
      console.log('No file selected');
    }
  };

  return (
    <ProfileLayout>
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>New Policy</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Policy has been added!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name:</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={data.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  valid={data.name.length > 0}
                />
              </FormGroup>
              <FormGroup>
                <Label for="file">File:</Label>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  accept=".doc,.docx,.pdf" // Specify accepted file types
                  required
                  multiple
                />
                <FormText>
                  Only .doc,.docx,.pdf are allowed.&nbsp;
                </FormText>
              </FormGroup>
              <Table>
                <tbody>
                {data.documents.map((file, index) => (
                  <tr key={index}>
                    <td>{file.name}</td>
                    <td><Button size="sm" onClick={() => handleRemoveFile(index)}><FontAwesomeIcon icon={faTimesCircle} /></Button></td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <Button block={true}>{isUploading ? <span className="loader"></span> : <span>Submit</span>}</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default CreatePolicy;
