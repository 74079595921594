import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, Container, FormGroup, Input, Row, Table} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {POLICIES_URL} from "../constants";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import AppPagination from "../components/AppPagination";


function Policies () {
  let {authTokens} = useContext(AuthContext);
  const [policies, setPolicies] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);


  const fetchPolicies = async (page, search_text) => {
    try {
      const url = POLICIES_URL + "?page=" + page + (search_text === undefined ? "" : "&search=" + search_text);
      const response = await fetch( url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const data = await response.json();
        setPolicies(data.results);
        setTotalPages(data.total_pages);
      } else {
        // Handle error responses
        console.error('Failed to fetch policies');
      }
    } catch (error) {
      console.error('Failed to fetch policies:', error);
    }
  };
  const handleSearchTextChange = ({ currentTarget: input }) => {
    fetchPolicies(currentPage, input.value);
  }

  useEffect(() => {
    fetchPolicies(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchPolicies(pageNumber);
  };

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h1>Policies</h1>
          </Col>
          <Col sm={4}>
            <Link to="/policies/create">
              <Button color="dark" outline={true} size="lg">Create Policy</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
                <Input
                  type="text"
                  id="serach-name"
                  name="search"
                  onChange={(e) => {
                    handleSearchTextChange(e);
                  }}
                  placeholder="Search by name"
                />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {policies.length > 0 ? (
            <Table bordered style={{"textAlign": "center", "marginTop": "50px"}}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Documents</th>
                  <th>Created at</th>
                  <th>Delete</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {policies.map((item, index) => (
                  <tr key={index}>
                    <td><Link to={`/policies/${item.id}/edit/`}>{item.id}</Link></td>
                    <td>{item.name}</td>
                    <td>{item.documents.map((doc, doc_index) => (<div key={doc_index}><Link to={`/documents/${doc.id}/`}>{doc.name}</Link></div>))}</td>
                    <td>{item.created}</td>
                    <td><Link to={`/policies/${item.id}/delete/`} style={{"color": "darkgray"}}><FontAwesomeIcon icon={faTimesCircle} /></Link></td>
                    <td>{item.extraction_status && <Link to={`/policies/${item.id}/chat/`}><Button color="success">Ask</Button></Link>}</td>
                  </tr>
                ))}
              </tbody>
            </Table>) : (<p style={{"marginTop": "50px"}}>No clients created yet!</p>)
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <AppPagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default Policies;
