import Layout from "./Layout";
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {RESET_PASSWORD_URL} from "../constants";
import React, {useState} from "react";
import {useParams} from "react-router-dom";


function ResetPasswordConfirm() {
  const { uid, token } = useParams();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    new_password_1: '',
    new_password_2: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const resetPasswordConfirm = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(RESET_PASSWORD_URL + uid + "/" + token + "/confirm/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        setData({
          new_password_1: '',
          new_password_2: ''
        })
      } else {
        const resp = await response.json();
        setErrors(resp.errors);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        // Handle error responses
        console.error('Failed to reset password.');
      }
    } catch (error) {
      console.error('Error resetting new password:', error);
    }
  };

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col>
            <h3>Reset password!</h3>
            <p>Please enter a new password.</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Password has been successfully reset!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">
                <ul style={{ margin: 0}}>
                {errors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
                </ul>
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={resetPasswordConfirm}>
              <FormGroup>
                <Label for="new_password_1">New password:</Label>
                <Input
                  type="password"
                  name="new_password_1"
                  id="new_password_1"
                  value={data.new_password_1}
                  onChange={handleChange}
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="new_password_2">Confirm password:</Label>
                <Input
                  type="password"
                  name="new_password_2"
                  id="new_password_2"
                  value={data.new_password_2}
                  onChange={handleChange}
                  required={true}
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default ResetPasswordConfirm;
