import React, {useState} from 'react';
import Layout from "./Layout";
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import axios from "axios";
import {REGISTRATION_API_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";

function SignUp() {
  const isDisabled= false;
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(REGISTRATION_API_URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        setFormData({first_name: '',
          last_name: '',
          email: '',
          password: '',
        })
      }).catch((error) => {
        setError(extractErrorMessage(error.response.data));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      });
    } catch (error) {
      setError("Unexpected Error. Please contact admin.");
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
    }
  };

  return (
    <Layout>
      <Container className="mt-5">
        {isDisabled &&
          <Row>
            <Col>
              <Button outline={true} color="danger">SignUp is currently not open!</Button>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <h1>Sign Up</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Please check your inbox (and spam) and verify your email.</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="firstName">First name:</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="firstName"
                  value={formData.first_name}
                  onChange={(e) => {handleChange(e);}}
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">Last name:</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="lastName"
                  value={formData.last_name}
                  onChange={(e) => {handleChange(e);}}
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  disabled={isDisabled}
                  onChange={(e) => {handleChange(e);}}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password:</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  value={formData.password}
                  onChange={(e) => {handleChange(e);}}
                  required
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default SignUp;
