import React, {useContext, useEffect} from 'react';
import AuthContext from "../utils/AuthProvider";
import {Col, Container, Row} from "reactstrap";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import './css/Profile.scss';

function ProfileLayout ({children}) {
  let {user} = useContext(AuthContext);
  useEffect(()  => {
    document.body.classList.add('bg-secondary');
    return () => {
        document.body.classList.remove('bg-secondary');
    };
});
  return (
    <div style={{minHeight: "800px"}}>
      {/* Navbar */}
      {user &&
        <Container fluid className="g-0">
          <Row>
            <Col md={2}>
              <Sidebar />
            </Col>
            <Col md={10}>
              {/* Content */}
              <div id="profile-main">
                {children}
              </div>
            </Col>
          </Row>
        </Container>
      }
      {/* Footer */}
      <Footer/>
    </div>
  );
}
export default ProfileLayout;
