import React from 'react';
import Layout from "./Layout";
import {Col, Container, Row} from "reactstrap";

function Home () {
  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>Claims Management</h1>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
export default Home;
