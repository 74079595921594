import React, {useContext} from 'react';
import Layout from "./Layout";
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import AuthContext from "../utils/AuthProvider";
import {Link} from "react-router-dom";

function LogIn() {
  let {loginUser, loginError} = useContext(AuthContext);

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col sm={6}>
            {loginError && (
              <Alert color="danger">{loginError}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h1>Log In</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={loginUser}>
              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="password">Password:</Label>
                <Input
                  type="password"
                  name="password"
                  id="password"
                  required
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Link to="/reset-password" className="link" style={{ float: "left"}}>Forgot password?</Link>
            <Link to="/resend-email-verification" className="link" style={{float: "right"}}>Verify email</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default LogIn;
