import React, {useEffect, useState} from 'react';
import Layout from "./Layout";
import {Alert, Col, Container, Row} from "reactstrap";
import {useParams} from "react-router-dom";
import {VERIFY_EMAIL_URL} from "../constants";

function EmailVerification() {
  const { uid, token } = useParams();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errors, setErrors] = useState(null);

  const verifyEmail = async (e) => {
    try {
      const response = await fetch(VERIFY_EMAIL_URL + uid + "/" + token + "/confirm/", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
      } else {
        const resp = await response.json();
        setErrors(resp.errors);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        // Handle error responses
        console.error('Failed to verify email.');
      }
    } catch (error) {
      console.error('Error verifying email:', error);
    }
  };

  useEffect(() => {
    verifyEmail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Use match.params.itemId as a dependency to re-fetch when the URL changes

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col>
            <h1>Email verification</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Email has been successfully verified!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">
                <ul style={{ margin: 0}}>
                {errors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
                </ul>
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default EmailVerification;
