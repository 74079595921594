import React, {useContext, useEffect} from 'react';
import AuthContext from "../utils/AuthProvider";


const LogOut = () => {
  const { logoutUser } = useContext(AuthContext);

  useEffect(() => {
    logoutUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default LogOut;
