export const extractErrorMessage  = (data) => {
  if (Array.isArray(data)) {
    return data[0]
  };
  for (const key in data) {
    if (Array.isArray(data[key]) && data[key].length > 0) {
      return key + " : " + data[key][0];
    }
  }
}
export const convertToFormData = (dictionary) => {
  const formData = new FormData();
  for (const key in dictionary) {
    formData.append(key, dictionary[key]);
  }
  return formData;
};

export const extractFilename = (filePath) =>{
  const pathArray = filePath.split(/[\\/]/); // Split by forward slash or backslash
  return pathArray[pathArray.length - 1].split(".")[0];
}
