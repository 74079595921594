import './App.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import PrivateRoute from "./utils/PrivateRoute";
import LogIn from "./pages/LogIn";
import {AuthProvider} from "./utils/AuthProvider";
import Profile from "./pages/Profile";
import LogOut from "./pages/LogOut";
import Policies from "./pages/Policies";
import CreatePolicy from "./pages/CreatePolicy";
import EditPolicy from "./pages/EditPolicy";
import DeletePolicy from "./pages/DeletePolicy";
import DocumentDetails from "./pages/DocumentDetails";
import SinglePolicyChats from "./pages/SinglePolicyChats";
import LegalChats from "./pages/LegalChats";
import ChangePassword from "./pages/ChangePassword";
import SessionChats from "./pages/SessionChats";
import ResetPassword from "./pages/ResetPassword";
import ResetPasswordConfirm from "./pages/ResetPasswordConfirm";
import DeactivateAccount from "./pages/DeactivateAccount";
import EmailVerification from "./pages/EmailVerification";
import ResendVerificationEmail from "./pages/ResendVerificationEmail";
import HistoricalPolicyChats from "./pages/HistoricalPolicyChats";
import HistoricalLegalChats from "./pages/HistoricalLegalChats";
import HelpfulQuestions from "./pages/HelpfulQuestions";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route exact path="/signup" element={<SignUp />} />
          <Route exact path="/login" element={<LogIn />} />
          <Route exact path="/" element={<Home />} />
          <Route exact path="/resend-email-verification" element={<ResendVerificationEmail />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/reset-password/:uid/:token/confirm/" element={<ResetPasswordConfirm />} />
          <Route exact path="/verify-email/:uid/:token/confirm/" element={<EmailVerification />} />
          <Route exact path="/change-password" element={<PrivateRoute><ChangePassword /></PrivateRoute>} />
          <Route exact path="/deactivate" element={<PrivateRoute><DeactivateAccount /></PrivateRoute>} />
          <Route exact path="/logout" element={<PrivateRoute><LogOut /></PrivateRoute>} />
          <Route exact path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route exact path="/policies" element={<PrivateRoute><Policies /></PrivateRoute>} />
          <Route exact path="/policies/create" element={<PrivateRoute><CreatePolicy /></PrivateRoute>} />
          <Route exact path="/policies/:objectId/edit/" element={<PrivateRoute><EditPolicy /></PrivateRoute>} />
          <Route exact path="/policies/:objectId/delete/" element={<PrivateRoute><DeletePolicy /></PrivateRoute>} />
          <Route exact path="/policies/:objectId/chat/" element={<PrivateRoute><SinglePolicyChats /></PrivateRoute>} />
          <Route exact path="/documents/:objectId/" element={<PrivateRoute><DocumentDetails /></PrivateRoute>} />
          <Route exact path="/legal-chat" element={<PrivateRoute><LegalChats /></PrivateRoute>} />
          <Route exact path="/historical-legal-chats" element={<PrivateRoute><HistoricalLegalChats /></PrivateRoute>} />
          <Route exact path="/historical-policy-chats" element={<PrivateRoute><HistoricalPolicyChats /></PrivateRoute>} />
          <Route exact path="/session/:objectId/" element={<PrivateRoute><SessionChats /></PrivateRoute>} />
          <Route exact path="/helpful-questions/general" element={<PrivateRoute><HelpfulQuestions type="general" /></PrivateRoute>} />
          <Route exact path="/helpful-questions/policy" element={<PrivateRoute><HelpfulQuestions type="policy" /></PrivateRoute>} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}
export default App;
