import React, {useContext} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AuthContext from "../utils/AuthProvider";
import {Col, Container, Row} from "reactstrap";

function Layout ({children}) {
  let {user} = useContext(AuthContext);
  return (
    <div>
      {/* Navbar */}
      <Header/>
      {user &&
        <Container>
          <Row>
            <Col><div className="welcome-name">Welcome, {user.first_name} {user.last_name}!</div></Col>
          </Row>
        </Container>
      }
      {/* Content */}
      <div id="main">
          {children}
      </div>
      {/* Footer */}
      <Footer/>
    </div>
  );
}
export default Layout;
