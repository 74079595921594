import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Container, Row} from "reactstrap";
import {DOCUMENTS_URL} from "../constants";
import PDFViewer from "../components/PdfViewer";
import './css/DocumentDetails.scss';
import AuthContext from "../utils/AuthProvider";
import ProfileLayout from "./ProfileLayout";

function DocumentDetails() {
  const { objectId } = useParams();
  let {authTokens} = useContext(AuthContext);
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const downloadJson = () => {
    const jsonBlob = new Blob([JSON.stringify(item, null, 2)], {
      type: "application/json",
    });

    const jsonUrl = URL.createObjectURL(jsonBlob);
    const link = document.createElement("a");
    link.href = jsonUrl;
    link.download = item.name + ".json";
    link.click();

    // Clean up by revoking the URL
    URL.revokeObjectURL(jsonUrl);
  };

  const fetchDocumentDetails = async () => {
    try {
      const response = await fetch(DOCUMENTS_URL + objectId + "/", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const data = await response.json();
        setItem(data);
        setLoading(false);
      } else {
        // Handle error responses
        console.error('Failed to fetch document details');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching document details:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectId]); // Use match.params.itemId as a dependency to re-fetch when the URL changes

  return (
    <ProfileLayout>
      <Container className="mt-5 document-details">
        <Row>
          <Col>
            {loading ? 'Loading ...' : (item ? (
              <div className="file-name"><h1>{item.name}</h1></div>
            ) : 'Item not found')}
          </Col>
        </Row>
        {item &&
          <Row>
            <Col>
              <Button style={{"margin": "10px 0"}} size="sm" onClick={downloadJson}>Download JSON</Button>
            </Col>
          </Row>
        }
        {item &&
          <Row>
            <Col lg={6}>
              {item.upload.endsWith('.pdf') ?
                <PDFViewer pdfUrl={item.upload}/> : <Link to={item.upload}>{item.upload}</Link>
              }
            </Col>
            <Col lg={6}>
              <div style={{height: "1000px", overflowY: "auto"}}>
                <pre>
                  <code>
                    {item.content !== null ? item.content.page_contents.join("\n\n--------------------------------\n\n") : "Text has not been extracted yet."}
                  </code>
                </pre>
              </div>
            </Col>
          </Row>
        }
      </Container>
    </ProfileLayout>
  );
}

export default DocumentDetails;
