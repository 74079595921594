import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {CHATS_URL} from "../constants";
import ChatBox from "../components/ChatBox";


function LegalChats () {
  let {authTokens} = useContext(AuthContext);
  const [sessionID, setSessionID] = useState(null);
  const createChatSession = async () => {
    try {
      const response = await fetch(CHATS_URL + "session/create/", {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({policy: null}),
      });
      if (response.ok) {
        const data = await response.json();
        setSessionID(data.id);
      } else {
        // Handle error responses
        console.error('Failed to fetch session id.');
      }
    } catch (error) {
      console.error('Error fetching session id:', error);
    }
  };


  useEffect(() => {
    createChatSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Use match.params.itemId as a dependency to re-fetch when the URL changes

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h3>Ask any general legal question.</h3>
          </Col>
        </Row>
        {sessionID !== null ? (
        <Row>
        <Col md={10} className="mx-auto mt-5">
          <ChatBox addChatInput={true} sub_url="history/legal/" sessionID={sessionID}/>
        </Col>
      </Row>
      ) : (
        <Row>
          <Col>
            Creating session...
          </Col>
        </Row>
      )}
      </Container>
    </ProfileLayout>
  );
}
export default LegalChats;
