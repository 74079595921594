import React from 'react';

function PDFViewer({pdfUrl}) {
  const openPdfInNewTab = () => {
    window.open(pdfUrl)
  }
  return (
    <div>
      <iframe title="PDF" src={pdfUrl} style={{width: '100%', height: '1000px'}}></iframe>
      <button onClick={openPdfInNewTab}>Open PDF</button>
    </div>
  )
}

export default PDFViewer;
