import React, {useContext, useEffect, useState} from 'react';
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import Select from 'react-select';
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {CITIES_URL, LANGUAGES_URL, PROFILE_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";
import {Link} from "react-router-dom";


function Profile () {
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [citySuggestions, setCitySuggestions] = useState([]);

  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    city: "",
    state: "",
    country: "",
    zip_code: "",
    mobile: "",
    phone: ""
  });

  const fetchLanguages = async () => {
    try {
      const response = await fetch(LANGUAGES_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setLanguages(data.results);
      } else {
        console.error('Failed to fetch languages.');
      }
    } catch (error) {
      console.error('Error fetching languages:', error);
    }
  };

  const fetchProfileDetails = async () => {
    try {
      const response = await fetch(PROFILE_URL, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const profileData = await response.json();
        setProfile(profileData);
        setSelectedLanguages(profileData.languages.map(obj => ({value: obj.code, label: obj.name})));
      } else {
        // Handle error responses
        console.error('Failed to fetch profile details');
      }
    } catch (error) {
      console.error('Error fetching profile details:', error);
    }
  };
  useEffect(() => {
    fetchProfileDetails();
    fetchLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCityChange = async (e) => {
    const value = e.target.value;
    setProfile({
      ...profile,
      city: value
    })
    try {
      const response = await fetch(CITIES_URL +  "?startswith=" + value, {
        method: 'GET',
        headers: {'Content-Type': 'application/json'}
      });
      if (response.ok) {
        const citiesData = await response.json();
        setCitySuggestions(citiesData.results.splice(0, 10));
      } else {
        // Handle error responses
        console.error('Failed to fetch city details');
      }
    } catch (error) {
      console.error('Error fetching city details:', error);
    }
  };

  const handleCitySuggestion = (e) => {
    setProfile({
      ...profile, // Copy the existing dictionary
      city: e.target.getAttribute("city"), // Update specific keys
      state: e.target.getAttribute("state"),
      country: e.target.getAttribute("country")
    });
  }

  const handleLanguageChange = selectedOptions => {
    setSelectedLanguages(selectedOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const formFieldValues = {};
    formData.forEach((value, key) => {
      formFieldValues[key] = value ? value : null;
    });
    formFieldValues["languages"] = selectedLanguages.map(obj => ({code: obj["value"], name: obj["label"]}));
    try {
      const response = await fetch(PROFILE_URL, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formFieldValues)
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
      } else {
        const responseData = await response.json();
        setError(extractErrorMessage(responseData));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error updating profile details:', error);
    }
  };

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h1>Profile</h1>
          </Col>
          <Col sm={4}>
            <Link to="/change-password" style={{marginRight: 10}}>
              <Button color="dark" outline={true} size="sm">Change password</Button>
            </Link>
            <Link to="/deactivate">
              <Button color="danger" outline={true} size="sm">Delete</Button>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Profile has been updated successfully!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  defaultValue={profile.email}
                  disabled
                />
              </FormGroup>
              <FormGroup>
                <Label for="first-name">First name:</Label>
                <Input
                  type="text"
                  name="first_name"
                  id="first-name"
                  defaultValue={profile.first_name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="last-name">Last name:</Label>
                <Input
                  type="text"
                  name="last_name"
                  id="last-name"
                  defaultValue={profile.last_name}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address-line-1">Address first line:</Label>
                <Input
                  type="text"
                  name="address_line_1"
                  id="address-line-1"
                  defaultValue={profile.address_line_1}
                />
              </FormGroup>
              <FormGroup>
                <Label for="address-line-2">Address second line:</Label>
                <Input
                  type="text"
                  name="address_line_2"
                  id="address-line-2"
                  defaultValue={profile.address_line_2}
                />
              </FormGroup>
              <FormGroup>
                <Label for="city">City:</Label>
                <Input
                  type="text"
                  name="city"
                  id="city"
                  value={profile.city === null ? '' : profile.city}
                  onChange={handleCityChange}
                />
              </FormGroup>
              <ul>
                {citySuggestions.map((suggestion, index) => (
                  <li key={index}><Button className="city-option" onClick={handleCitySuggestion} cityid={suggestion.id} city={suggestion.name} state={suggestion.state} country={suggestion.country}>{suggestion.name}, {suggestion.state}, {suggestion.country}</Button></li>
                ))}
              </ul>
              <FormGroup>
                <Label for="state">State:</Label>
                <Input
                  type="text"
                  name="state"
                  id="state"
                  defaultValue={profile.state}
                />
              </FormGroup>
              <FormGroup>
                <Label for="zip-code">Zip code:</Label>
                <Input
                  type="text"
                  name="zip_code"
                  id="zip-code"
                  defaultValue={profile.zip_code}
                />
              </FormGroup>
              <FormGroup>
                <Label for="country">Country:</Label>
                <Input
                  type="text"
                  name="country"
                  id="country"
                  defaultValue={profile.country}
                />
              </FormGroup>
              <FormGroup>
                <Label for="mobile">Mobile:</Label>
                <Input
                  type="text"
                  name="mobile"
                  id="mobile"
                  defaultValue={profile.mobile}
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">Telephone:</Label>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  defaultValue={profile.phone}
                />
              </FormGroup>
              <FormGroup>
                <Label for="selectLanguages">Languages:</Label>
                <Select
                  id="selectLanguages"
                  // closeMenuOnSelect={false}
                  isMulti
                  name="countries"
                  options={languages.map(language => ({ value: language.code, label: language.name }))}
                  value={selectedLanguages}
                  onChange={handleLanguageChange}
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default Profile;
