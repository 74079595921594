function Footer () {
  return (
    <footer>
      <div className="row">
        <div className="col">
          <p>&copy; 2023 PLG Law</p>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
