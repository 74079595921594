import React from 'react';
import {Col, Container, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import ChatBox from "../components/ChatBox";


function HistoricalLegalChats () {
  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h3>Legal questions asked.</h3>
          </Col>
        </Row>
        <Row>
          <Col md={10} className="mx-auto mt-5">
            <ChatBox addChatInput={false} sub_url="history/legal/" sessionID={null}/>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default HistoricalLegalChats;
