import React, {useContext, useEffect, useRef, useState} from 'react';
import './css/ChatBox.scss';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faExternalLink, faRefresh, faThumbsUp, faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {CHATS_URL} from "../constants";
import AuthContext from "../utils/AuthProvider";
import {Button, Input} from "reactstrap";

const ChatBox = ({ addChatInput, sub_url, sessionID }) => {
  let {authTokens} = useContext(AuthContext);
  const chatBoxRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [likedChatMessages, setLikedChatMessages] = useState([]);
  const [bookmarkedChatMessages, setBookmarkedChatMessages] = useState([]);
  const [historicalChatSessions, setHistoricalChatSessions] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);

  const addLineBreaks = (text) => {
    const lines = text.split('\n');
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))
  }
  const getChats = async () => {
    setInputDisabled(true);
    try {
      const response = await fetch(CHATS_URL + sessionID + "/session/", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setChatMessages(data);
        setLikedChatMessages(data.map(item => item.liked));
        setBookmarkedChatMessages(data.map(item => item.bookmarked));
      } else {
        // Handle error responses
        console.error('Failed to send new chat.');
      }
    } catch (error) {
      console.error('Error sending new chat:', error);
    }
    setInputDisabled(false);
  };
  const sendChat = async () => {
    setInputDisabled(true);
    try {
      const response = await fetch(CHATS_URL + sessionID + "/chat/create/", {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({role: "user", content: newMessage}),
      });
      if (response.ok) {
        const data = await response.json();
        setChatMessages(data);
        setLikedChatMessages(data.map(item => item.liked));
        setBookmarkedChatMessages(data.map(item => item.bookmarked));
      } else {
        // Handle error responses
        console.error('Failed to send new chat.');
      }
    } catch (error) {
      console.error('Error sending new chat:', error);
    }
    setInputDisabled(false);
  };
  const handleSendMessage = () => {
    if (newMessage.trim() !== '') {
      setChatMessages([...chatMessages, ...[{ content: newMessage, role: 'user' }, { content: "Processing...", role: 'assistant' }]]);
      setLikedChatMessages(chatMessages.map(item => item.liked));
      setBookmarkedChatMessages(chatMessages.map(item => item.bookmarked));
      sendChat();
      setNewMessage('');
    }
  };
  const handleChatInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };
  const getHistoricalChatSessions = async () => {
    try {
      const response = await fetch(CHATS_URL + sub_url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setHistoricalChatSessions(data.results);
      } else {
        // Handle error responses
        console.error('Failed to fetch chat history.');
      }
    } catch (error) {
      console.error('Error fetching chat history:', error);
    }
  };
  const removeChatSession = async (session_id) => {
    try {
      const response = await fetch(CHATS_URL + "session/" + session_id + "/delete/", {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const resp = await response.json();
        console.log(resp);
      } else {
        // Handle error responses
        console.error('Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };
  const likeChat = async (event, chat_id, index) => {
    const updatedArray = [...likedChatMessages];
    updatedArray[index] = !updatedArray[index];
    setLikedChatMessages(updatedArray);
    const elem = event.target;
    try {
      const response = await fetch(CHATS_URL + "session/chat/" + chat_id + "/like/", {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({liked: !elem.classList.contains('selected')}),
      });
      if (response.ok) {
        if (elem.classList.contains('selected')) {
          elem.classList.remove('selected');
        } else {
          elem.classList.add('selected');
        }
      } else {
        // Handle error responses
        console.error('Failed to like chat.');
      }
    } catch (error) {
      console.error('Error liking a chat:', error);
    }
  };

  const bookmarkChat = async (event, chat_id, index) => {
    const updatedArray = [...bookmarkedChatMessages];
    updatedArray[index] = !updatedArray[index];
    setBookmarkedChatMessages(updatedArray);
    const elem = event.target;
    try {
      const response = await fetch(CHATS_URL + "session/chat/" + chat_id + "/bookmark/", {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({bookmarked: !elem.classList.contains('selected')}),
      });
      if (response.ok) {
        if (elem.classList.contains('selected')) {
          elem.classList.remove('selected');
        } else {
          elem.classList.add('selected');
        }
      } else {
        // Handle error responses
        console.error('Failed to bookmark chat.');
      }
    } catch (error) {
      console.error('Error bookmarking a chat:', error);
    }
  };
  const getRefreshedChats = async () => {
    setChatMessages([...chatMessages, ...[{ content: "Processing...", role: 'assistant' }]]);
    setLikedChatMessages(chatMessages.map(item => item.liked));
    setBookmarkedChatMessages(chatMessages.map(item => item.bookmarked));
    try {
      const response = await fetch(CHATS_URL + sessionID + "/chat/refresh-latest-response/", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        const data = await response.json();
        setChatMessages(data);
        setLikedChatMessages(data.map(item => item.liked));
        setBookmarkedChatMessages(data.map(item => item.bookmarked));
      } else {
        // Handle error responses
        console.error('Failed to refresh response.');
      }
    } catch (error) {
      console.error('Error refreshing response:', error);
    }
  };
  const updateChatSessionName = async (session_id, name) => {
    try {
      const response = await fetch(CHATS_URL + "session/" + session_id + "/update/", {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({name: name}),
      });
      if (!response.ok) {
        // Handle error responses
        console.error('Failed to update chat session name.');
      }
    } catch (error) {
      console.error('Error updating chat session name:', error);
    }
  };
  const handleRemoveChatSession = (index, session_id) => {
    const sessions = [...historicalChatSessions];
    removeChatSession(session_id);
    sessions.splice(index, 1);
    setHistoricalChatSessions(sessions);
  };
  const handleChatSessionNameUpdateInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      updateChatSessionName(event.target.getAttribute('data-session'), event.target.value);
    }
  }
  const handleContentChange = (e, index) => {
    let newData = [ ...historicalChatSessions ];
    newData[index].name = e.target.value;
    setHistoricalChatSessions(newData);
  };


  useEffect(() => {
    if (sessionID) {
      getChats();
    }
    if (sub_url !== undefined) {
      getHistoricalChatSessions();
    }
    // Scroll to the bottom when messages change
    if (chatBoxRef !== null) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div ref={chatBoxRef} style={{ height: '400px', border: '1px solid #ccc', overflowY: 'auto', padding: '15px' }}>
        {historicalChatSessions && (
          <div className="historical-chat-container">
            {historicalChatSessions.slice(0, 4).map((msg, index) => (
            <div key={index} className="historical-chat-box">
              <div>
                <Input
                type="text"
                value={msg.name}
                onChange={(e) => handleContentChange(e, index)}
                onKeyDown={handleChatSessionNameUpdateInputKeyPress}
                contentEditable="true"
                style={{ border: '1px solid #ccc', padding: '3px'}}
                data-session={msg.id}
                />
              </div>
              <div>
              <Link to={`/session/${msg.id}/`} key={index}>
                <FontAwesomeIcon icon={faExternalLink} size="xs" style={{"color": "darkgrey", "float": "left", "margin": "3px"}}/>
              </Link>
              <Link to="#">
                <FontAwesomeIcon size="xs" style={{"color": "darkgrey", "float": "right", "margin": "3px"}} onClick={() => handleRemoveChatSession(index, msg.id)} icon={faTimesCircle} />
              </Link>
              </div>
            </div>
            )
          )}
          </div>
        )}
        {chatMessages.map((msg, index) => (
          msg.role === 'user' ? (
            <div key={index} className='you-chat'>
              {msg.role === 'user' ? <strong>You:</strong> : ''} {addLineBreaks(msg.content)}
            </div>
          ) : (
            <div key={index} className='bot-chat'>
              <div key={`chat-${index}`} className='chat'>
                {addLineBreaks(msg.content)}
              </div>
              <div key={`chat-extras-${index}`} className='chat-extras'>
                <div key={`chat-like-${index}`} onClick={(event) => likeChat(event, msg.id, index)} data-chat-id={msg.id}>
                  <FontAwesomeIcon icon={faThumbsUp} className={(likedChatMessages[index] ? "selected": "deselected")}/>
                </div>
                <div key={`chat-bookmark-${index}`} onClick={(event) => bookmarkChat(event, msg.id, index)} data-chat-id={msg.id}>
                  <FontAwesomeIcon icon={faBookmark} className={(bookmarkedChatMessages[index] ? "selected": "deselected")}/>
                </div>
                {msg.id !== undefined && index === chatMessages.length - 1 && (
                  <div key={`chat-refresh-${index}`} onClick={getRefreshedChats} data-chat-id={msg.id}>
                    <FontAwesomeIcon icon={faRefresh}/>
                  </div>
                )}
              </div>
            </div>
          )
        ))}
      </div>
      {addChatInput &&
        <div>
        <Input
          type="text"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          className="mt-3"
          onKeyDown={handleChatInputKeyPress}
          disabled={inputDisabled}
        />
        <Button color="primary" onClick={handleSendMessage} className="mt-2">
          Send
        </Button>
        </div>
      }
    </div>
  );
};

export default ChatBox;
