import React from 'react';
import {Col, Container, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import ChatBox from "../components/ChatBox";
import {useParams} from "react-router-dom";


function SessionChats () {
  const { objectId } = useParams();
  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h3>Ask any general question.</h3>
          </Col>
        </Row>
        <Row>
        <Col md={10} className="mx-auto mt-5">
          <ChatBox addChatInput={true} sessionID={objectId}/>
        </Col>
      </Row>
      </Container>
    </ProfileLayout>
  );
}
export default SessionChats;
