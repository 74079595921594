import React, {useContext, useState} from 'react';
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {CHANGE_PASSWORD_URL} from "../constants";


function ChangePassword () {
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errors, setErrors] = useState(null);
  const [data, setData] = useState({
    old_password: '',
    new_password_1: '',
    new_password_2: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(CHANGE_PASSWORD_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        setData({
          old_password: '',
          new_password_1: '',
          new_password_2: ''
        })
      } else {
        const responseData = await response.json();
        setErrors(responseData.errors);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col>
            <h3>Change password</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Client has been updated successfully!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">
                <ul style={{ margin: 0}}>
                {errors.map((err, index) => (
                  <li key={index}>{err}</li>
                ))}
                </ul>
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="old_password">Old password:</Label>
                <Input
                  type="password"
                  name="old_password"
                  id="old_password"
                  value={data.old_password}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="new_password_1">New password:</Label>
                <Input
                  type="password"
                  name="new_password_1"
                  id="new_password_1"
                  value={data.new_password_1}
                  onChange={handleChange}
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <Label for="new_password_2">Confirm password:</Label>
                <Input
                  type="password"
                  name="new_password_2"
                  id="new_password_2"
                  value={data.new_password_2}
                  onChange={handleChange}
                  required={true}
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default ChangePassword;
