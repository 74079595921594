import {Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink} from "reactstrap";
import {useContext, useState} from "react";
import AuthContext from '../utils/AuthProvider'

function Header() {
  let { user, logoutUser } = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(false);
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand href="/">
        PLG
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} />
      <Collapse isOpen={isOpen} navbar>
        {user &&
        <Nav navbar>
          <NavItem>
            <NavLink href="/profile">Profile</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#">Claims</NavLink>
          </NavItem>
        </Nav>
        }
        <Nav navbar className="ms-auto" >
          {!user &&
            <NavItem>
              <NavLink href="/signup">Sign up</NavLink>
            </NavItem>
          }
          {user ? (
            <NavItem>
              <NavLink href="#" onClick={logoutUser}>Logout</NavLink>
            </NavItem>
          ) : (
            <NavItem>
              <NavLink href="/login">Log in</NavLink>
            </NavItem>
          )}
        </Nav>
      </Collapse>
    </Navbar>
  );
}
export default Header;
