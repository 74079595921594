import React, {useContext, useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink} from 'reactstrap';
import './css/Sidebar.scss';
import AuthContext from "../utils/AuthProvider";

const Sidebar = () => {
  let {user, logoutUser} = useContext(AuthContext);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const toggleDropdown1 = () => {
    setIsOpen1(!isOpen1);
  };

  const toggleDropdown2 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleLinkClick = (url) => {
    window.location.href = url;
  };

  return (
    <div className="side-bar">
      <div className="welcome-box">Welcome,<br />{user.first_name} {user.last_name}!</div>
      <hr />
      <Nav vertical>
        <NavItem>
          <NavLink href="/profile">Profile</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/policies">Policies</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/legal-chat">Legal Chat</NavLink>
        </NavItem>
        <NavItem>
          <Dropdown isOpen={isOpen1} toggle={toggleDropdown1}>
            <DropdownToggle caret>
              Historical chats
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleLinkClick('/historical-legal-chats')}>
                Legal
              </DropdownItem>
              <DropdownItem onClick={() => handleLinkClick('/historical-policy-chats')}>
                Policy
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
        <NavItem>
          <Dropdown isOpen={isOpen2} toggle={toggleDropdown2}>
            <DropdownToggle caret>
              Helpful questions
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleLinkClick('/helpful-questions/general')}>
                General
              </DropdownItem>
              <DropdownItem onClick={() => handleLinkClick('/helpful-questions/policy')}>
                Policy
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem>
        <NavItem className="profile-logout">
          <NavLink href="#" onClick={logoutUser}>Logout</NavLink>
        </NavItem>
      </Nav>
    </div>
  );
};

export default Sidebar;
