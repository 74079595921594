import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {CHATS_URL} from "../constants";



function HelpfulQuestions ({type}) {
  console.log(type);
  let {authTokens} = useContext(AuthContext);
  const [questions, setQuestions] = useState([]);


  const fetchQuestions = async () => {
    try {
      const url = CHATS_URL + "helpful-questions/" + type +  "/";
      const response = await fetch( url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const data = await response.json();
        setQuestions(data);
      } else {
        // Handle error responses
        console.error('Failed to fetch helpful questions');
      }
    } catch (error) {
      console.error('Failed to fetch helpful questions:', error);
    }
  };

  useEffect(() => {
    fetchQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={8}>
            <h1>Helpful questions</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {questions.length > 0 && (
            <Table bordered style={{"textAlign": "left", "marginTop": "50px"}}>
              <thead>
                <tr>
                  <th>Question</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((item, index) => (
                  <tr key={index}>
                    <td>{item.question}</td>
                  </tr>
                ))}
              </tbody>
            </Table>)}
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default HelpfulQuestions;
