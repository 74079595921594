import {createContext, useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import jwtDecode from 'jwt-decode';
import {LOGIN_API_TOKEN_URL} from "../constants";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({children}) => {

    let [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null));
    let [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null));
    let [loading, setLoading] = useState(true);
    let [loginError, setLoginError] = useState(null);
    const navigate = useNavigate();

    let loginUser = async (e) => {
        e.preventDefault()
        const response = await fetch(LOGIN_API_TOKEN_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: e.target.email.value, password: e.target.password.value })
        });
        let data = await response.json();
        if(response.ok && data){
            localStorage.setItem('authTokens', JSON.stringify(data));
            setAuthTokens(data);
            console.log(jwtDecode(data.access));
            setUser(jwtDecode(data.access));
            navigate('/profile');
        } else {
            setLoginError(data.detail);
            console.log(data);
        }
    }

    const logout = () => {
        localStorage.removeItem('authTokens')
        setAuthTokens(null)
        setUser(null);
    }

    let logoutUser = (e) => {
        // e.preventDefault()
        logout();
        navigate('/login')
    }

    const updateToken = async () => {
        const response = await fetch(LOGIN_API_TOKEN_URL + 'refresh/', {
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body:JSON.stringify({refresh:authTokens?.refresh})
        })
        const data = await response.json()
        if (response.status === 200) {
            setAuthTokens(data)
            setUser(jwtDecode(data.access))
            localStorage.setItem('authTokens',JSON.stringify(data))
        } else {
            logout();
        }
        if (loading){
            setLoading(false);
        }
    }

    let contextData = {
        user:user,
        authTokens:authTokens,
        loginUser:loginUser,
        logoutUser:logoutUser,
        loginError:loginError,
    }

    useEffect(()=>{
        if (authTokens && loading){
            updateToken();
        }
        const REFRESH_INTERVAL = 1000 * 60 *  14;// 4 minutes
        let interval = setInterval(()=>{
            if(authTokens){
                updateToken()
            }
        }, REFRESH_INTERVAL)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[authTokens, loading])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )

}
