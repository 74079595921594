import React, {useContext, useEffect, useState} from 'react';
import './css/ChatBox.scss';
import AuthContext from "../utils/AuthProvider";
import {POLICIES_URL} from "../constants";
import './css/PolicySummary.scss';
import {Col, Container, Row} from "reactstrap";

const PolicySummary = ({ policyId }) => {
  let {authTokens} = useContext(AuthContext);
  const [policySummary, setPolicySummary] = useState(null);
  const [policyReportCard, setPolicyReportCard] = useState(null);
  const [policyType, setPolicyType] = useState(null);
  const [error, setError] = useState(null);


  const fetchPolicySummary = async () => {
    const timeout = 600*1000; // Set your desired timeout in milliseconds
    try {
      const response = await Promise.race([
        fetch(POLICIES_URL + policyId + "/summary/", {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
            'Content-Type': 'application/json',
          }
        }),
        new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Request timed out')), timeout)
        ),
      ]);
      const responseData = await response.json();
      setPolicySummary(responseData.summary.policy_summary);
      setPolicyReportCard(responseData.summary.policy_report_card);
      setPolicyType(responseData.summary.policy_type);
      setError(null);
    } catch (error) {
      console.log(error);
      setError('Error fetching policy summary:', error.message);
    }
  };

  const renderNameOfInsured = () => {
    return <ul>
      {policyReportCard["name_of_insured"].map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  }

  const renderPolicyPeriod = () => {
    return <ul>
      {typeof policyReportCard["policy_period"] === 'object' ? (Object.keys(policyReportCard["policy_period"]).map((key) => (
          <li key={key}>
            <strong>{key}:</strong> {policyReportCard["policy_period"][key]}
          </li>
      ))) : "not available"}
    </ul>
  }
  const renderPolicySummary = (obj,  depth = 0) => {
    return obj.map((item, index) => (
      <div key={index}>
        <h2>{item.headline}</h2>
        <ul key={index}>{item.content.map((sub_item, sub_index) => (
          <li key={sub_index}>
            {sub_item}
          </li>
        ))}
        </ul>
      </div>
    ));
  };

  useEffect(() => {
    fetchPolicySummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container fluid={true} className="policy-complete-summary">
      <Row>
        {!(policySummary || error) && <div className="blink" style={{fontStyle: "italic"}}>Summary is being fetched...</div>}
        {error && <div style={{fontStyle: "italic", color: "red"}}>{error}</div>}
      </Row>
      <Row>
        <Col sm={6}>
          {policyReportCard && (
            <div className="policy-box">
              <h1>Report Card</h1>
              <h2>Names of insured</h2>
              {renderNameOfInsured()}
              <h2>Policy Period</h2>
              {renderPolicyPeriod()}
              <h2>Property Address</h2>
              {policyReportCard["property_address"] || "not available"}
            </div>
          )}
        </Col>
        <Col sm={6}>
          {policyReportCard && (
            <div className="policy-box">
              <h1>Type</h1>
              <div className={`policy-type ${policyType.commercial ? 'active' : ''}`}>Commercial</div>
              <div className={`policy-type ${policyType.residential ? 'active' : ''}`}>Residential</div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {policySummary && (
            <div className="policy-box">
              <h1>Summary</h1>
              {renderPolicySummary(policySummary)}
            </div>)
          }
        </Col>
      </Row>
    </Container>
  );
};

export default PolicySummary;
