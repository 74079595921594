import React, {useContext, useState} from 'react';
import {Alert, Button, Col, Container, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {POLICIES_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";
import {useNavigate, useParams} from "react-router-dom";


function DeletePolicy () {
  const { objectId } = useParams();
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(POLICIES_URL +  objectId + "/delete/", {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        navigate("/policies")
      } else {
        const responseData = await response.json();
        setError(extractErrorMessage(responseData));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error deleting policy:', error);
    }
  };

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Policy has been deleted successfully!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Are you sure you want to delete policy id : {objectId}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="danger" onClick={handleSubmit}>Delete</Button>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default DeletePolicy;
