import React, {useContext, useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {DOCUMENTS_URL, POLICIES_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useParams} from "react-router-dom";
import PolicySummary from "../components/PolicySummary";


function EditPolicy () {
  const { objectId } = useParams();
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({name: "", documents: []});
  const [textExtractionStatus, setTextExtractionStatus] = useState(null);


  const fetchPolicyDetails = async () => {
    try {
      const response = await fetch(POLICIES_URL + objectId + "/", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const policyData = await response.json();
        setData(policyData);
      } else {
        // Handle error responses
        console.error('Failed to fetch client details');
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
    }
  };
  const fetchExtractionStatus = async () => {
    try {
      const response = await fetch(POLICIES_URL + objectId + "/text-extraction-status/", {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const responseData = await response.json();
        setTextExtractionStatus(responseData.extraction_status);
      } else {
        // Handle error responses
        console.error('Failed to fetch text extraction status details');
      }
    } catch (error) {
      console.error('Error fetching text extraction status details:', error);
    }
  };
  const removeDocument = async (doc_id) => {
    try {
      const response = await fetch(DOCUMENTS_URL + doc_id + "/delete/", {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        }
      });
      if (response.ok) {
        const resp = await response.json();
        console.log(resp);
      } else {
        // Handle error responses
        console.error('Failed to delete document');
      }
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };
  const handleChange = ({ currentTarget: input }) => {
    let newData = { ...data };
    newData[input.name] = input.value;
    setData(newData);
  };

  const handleFileChange = (e) => {
    const selectedFiles = data.documents.concat(Array.from(e.target.files));
    setData({...data, documents: selectedFiles});
  }
  const handleRemoveFile = (index) => {
    // Remove the file at the specified index
    const updatedFiles = [...data.documents];
    if (updatedFiles[index].id) {
      removeDocument(updatedFiles[index].id);
    }
    updatedFiles.splice(index, 1);
    setData({...data, documents: updatedFiles});
  };

  const submitPolicy = async () => {
    setIsUploading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    data.documents.forEach((item, index) => {
      if (item instanceof File) {
        formData.append("files", item);
      }
    });
    try {
      const response = await fetch(POLICIES_URL +  objectId + "/update/", {
        method: 'PUT',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authTokens.access}`
        }
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        setIsUploading(false);
      } else {
        console.error('Failed to create policy');
        const responseData = await response.json();
        setError(extractErrorMessage(responseData));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Failed to create policy', error);
      setError("Unexpected Error. Please contact admin.");
      setShowSuccessAlert(false);
      setShowErrorAlert(true);
      setIsUploading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (data.documents) {
      await submitPolicy();
    } else {
      console.log('No file selected');
    }
  };

  useEffect(() => {
    fetchPolicyDetails();
    fetchExtractionStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProfileLayout>
      <Container className="mt-5">
        <Row>
          <Col sm={10}>
            <h1>Policy</h1>
          </Col>
          <Col sm={2}>
            {textExtractionStatus === true && (<Button size="sm" color="success">Text extracted</Button>)}
            {textExtractionStatus === false && (<Button size="sm" color="danger">Text not extracted</Button>)}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Policy has been updated!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <Label for="name">Name:</Label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={data.name == null ? '' : data.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="file">File:</Label>
                <Input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  accept=".doc,.docx,.pdf" // Specify accepted file types
                  multiple
                />
                <FormText>
                  Only .doc,.docx,.pdf are allowed.&nbsp;
                </FormText>
              </FormGroup>
              <Table>
                <tbody>
                {data.documents.map((file, index) => (
                  <tr key={index}>
                    <td>{file.upload === undefined ? file.name : <Link to={`/documents/${file.id}/`}>{file.name}</Link>}</td>
                    <td><FontAwesomeIcon onClick={() => handleRemoveFile(index)} icon={faTimesCircle} /></td>
                  </tr>
                ))}
                </tbody>
              </Table>
              <Button block={true}>{isUploading ? <span className="loader"></span> : <span>Submit</span>}</Button>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <PolicySummary policyId={objectId}/>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default EditPolicy;
