import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const AppPagination = ({ currentPage, totalPages, onPageChange }) => {
  const pagesToShow = 5; // Number of page numbers to show below and above the current page

  const startPage = Math.max(1, currentPage - pagesToShow);
  const endPage = Math.min(totalPages, currentPage + pagesToShow);

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <Pagination>
      <PaginationItem disabled={currentPage === 1}>
        <PaginationLink previous onClick={() => onPageChange(currentPage - 1)} />
      </PaginationItem>
      {pageNumbers.map((number) => (
        <PaginationItem key={number} active={number === currentPage}>
          <PaginationLink onClick={() => onPageChange(number)}>
            {number}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={currentPage === totalPages}>
        <PaginationLink next onClick={() => onPageChange(currentPage + 1)} />
      </PaginationItem>
    </Pagination>
  );
};

export default AppPagination;
