import React, {useContext, useState} from 'react';
import {Alert, Button, Col, Container, Row} from "reactstrap";
import ProfileLayout from "./ProfileLayout";
import AuthContext from "../utils/AuthProvider";
import {PROFILE_URL} from "../constants";
import {extractErrorMessage} from "../utils/Utils";
import {useNavigate} from "react-router-dom";


function DeactivateAccount () {
  let {authTokens} = useContext(AuthContext);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(PROFILE_URL, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authTokens.access}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        navigate("/logout")
      } else {
        const responseData = await response.json();
        setError(extractErrorMessage(responseData));
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
      }
    } catch (error) {
      console.error('Error deleting account:', error);
    }
  };

  return (
    <ProfileLayout>
      <Container>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Account has been deleted successfully!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">{error}</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>Are you sure you want to delete your account? This action cannot be reverted back.</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button color="danger" onClick={handleSubmit}>Delete</Button>
          </Col>
        </Row>
      </Container>
    </ProfileLayout>
  );
}
export default DeactivateAccount;
