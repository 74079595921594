export const API_DOMAIN = process.env.NODE_ENV === 'production' ? "https://policymanager2.toplegalai.com/api" : "http://localhost:8000/api";
export const MEDIA_DOMAIN = process.env.NODE_ENV === 'production' ? "https://policymanager2.toplegalai.com" : "http://localhost:8000";
export const REGISTRATION_API_URL = API_DOMAIN + "/accounts/register/";
export const LOGIN_API_TOKEN_URL = API_DOMAIN + '/accounts/token/';
export const CHANGE_PASSWORD_URL = API_DOMAIN + '/accounts/change-password/';
export const RESET_PASSWORD_URL = API_DOMAIN + '/accounts/reset-password/';
export const RESEND_VERIFICATION_EMAIL_URL = API_DOMAIN + '/accounts/resend-verification-email/';
export const VERIFY_EMAIL_URL = API_DOMAIN + '/accounts/verify-email/';
export const PROFILE_URL = API_DOMAIN + '/accounts/profile/';
export const CITIES_URL = API_DOMAIN + '/utilities/cities/';
export const LANGUAGES_URL = API_DOMAIN + '/utilities/languages/';
export const CLIENTS_URL = API_DOMAIN + '/clients/';
export const POLICIES_URL = API_DOMAIN + '/policies/';
export const DOCUMENTS_URL = API_DOMAIN + '/documents/';
export const CHATS_URL = API_DOMAIN + '/chats/';
