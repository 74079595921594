import Layout from "./Layout";
import {Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {RESEND_VERIFICATION_EMAIL_URL} from "../constants";
import React, {useState} from "react";


function ResendVerificationEmail() {
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [error, setError] = useState(null);

  const resendEmail = async (e) => {
    e.preventDefault();
    const clearForm = () => {
      const emailInput = document.querySelector('input[name="email"]');
      emailInput.value = '';
    }
    try {
      const response = await fetch(RESEND_VERIFICATION_EMAIL_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email: e.target.email.value})
      });
      if (response.ok) {
        setShowSuccessAlert(true);
        setShowErrorAlert(false);
        clearForm();
      } else {
        const resp = await response.json();
        setError(resp.error);
        setShowSuccessAlert(false);
        setShowErrorAlert(true);
        // Handle error responses
        console.error('Failed to resend verification email.');
      }
    } catch (error) {
      console.error('Error resending verification email:', error);
    }
  };

  return (
    <Layout>
      <Container className="mt-5">
        <Row>
          <Col>
            <h3>Resend email verification!</h3>
            <p>Please enter your email address below, and we'll resend the email for verification.</p>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            {showSuccessAlert && (
              <Alert color="success">Verification email has been sent. Please check your inbox (and spam)!</Alert>
            )}
            {showErrorAlert && (
              <Alert color="danger">
                {error}
              </Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form onSubmit={resendEmail}>
              <FormGroup>
                <Label for="email">Email:</Label>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  required
                />
              </FormGroup>
              <Button type="submit" block={true}>Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default ResendVerificationEmail;
